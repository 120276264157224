import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import { StaticImage } from "gatsby-plugin-image"

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Podcasts - Kimberly Plomp, PhD</title>
            <meta name="description" content="Podcast information" />
            <meta name="keywords" content="podcast,episode,host" />
        </Helmet>

        <BannerLanding title="Podcasts" subtitle="Podcast hosting and episode participation" />

        <div id="main">
            <section id="one" className="spotlights">
                <section>
                        <StaticImage src="../assets/images/sotsa.webp" alt="Screens of the Stone Age podcast" className="image" />
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h2>Screens of the Stone Age</h2>
                            </header>
                            <p>If you love movies, science, and archaeology, check out the podcast I host with Josh Lindal and Ross Barnett. We discuss the scientific and historical accuracies and inaccuracies of movies that have anything to do with Pleistocene life.</p>
                            <p>It is available at <a href="https://pasc-scpa.ca/sotsa" target="_blank" rel="noreferrer">https://pasc-scpa.ca/sotsa</a> or wherever you listen to podcasts!</p>
                            <p>Follow the show on Twitter <a href="https://twitter.com/SotSA_Podcast" target="_blank" rel="noreferrer">@SotSA_Podcast</a></p>
                            <p>Sponsored by the <a href="https://pasc-scpa.ca/" target="_blank" rel="noreferrer">Palaeoanthropology Society of Canada</a></p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h2>Episode Appearances</h2>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>March 4, 2019</td>
                                            <td>Women In Archaeology (Interview in Bioarchaeology with Dr. Kim Plomp)</td>
                                            <td><a href="https://womeninarchaeology.com/2019/03/04/interview-in-bioarchaeology-with-dr-kim-plomp/" target="_blank" rel="noreferrer">https://womeninarchaeology.com/2019/03/04/interview-in-bioarchaeology-with-dr-kim-plomp/</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Landing
